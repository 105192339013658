body {
  background-color: #373b41;
}

.dropdownLabel {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

#historyContainer {
  min-height: 6em;
  max-height: 6em;
  overflow-y: scroll;
  overflow-wrap: break-word;
}

#chatContainer {
  min-height: 25em;
  max-height: 25em;
  overflow-y: scroll;
  overflow-wrap: break-word;
}
